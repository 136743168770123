import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import "~/styles/index.css";

import {
  ColorSchemeScript,
  mantineHtmlProps,
  MantineProvider,
} from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { Notifications } from "@mantine/notifications";
import { createBrowserClient } from "@supabase/ssr";
import { SupabaseClient, User } from "@supabase/supabase-js";
import { getUser } from "modules/auth/authHelper.server";
import { impersonationService } from "modules/auth/service/impersonation.service";
import { flagsService } from "modules/flags/flags.service";
import { UserFlagsProjectionJson } from "modules/flags/userFlags.projection";
import { TeacherProfile } from "modules/profiles/profile.types";
import { profileService } from "modules/profiles/service/profile.service";
import { useEffect, useState } from "react";
import { getToast } from "remix-toast";
import { Tuuid } from "supabase/drizzle/jsonTypes";
import { RootErrorBoundary } from "./components/ErrorBoundary/RootErrorBoundary";
import { ImpersonationBanner } from "./components/ImpersonationBanner";
import {
  showErrorToast,
  showSuccessToast,
} from "./components/Notifications/customNotifications";
import { Posthog } from "./posthog";
import { customTheme } from "./styles/theme";
import "./tailwind.css";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export interface OutletContext {
  supabase: SupabaseClient;
  user: User | null;
  userFlags: UserFlagsProjectionJson;
  teacherProfile: TeacherProfile | null;
}

// eslint-disable-next-line custom/require-protected-routes
export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (
    !process.env.SUPABASE_URL ||
    !process.env.SUPABASE_ANON_KEY ||
    !process.env.POSTHOG_KEY
  ) {
    throw new Error(
      "Missing SUPABASE_URL, SUPABASE_ANON_KEY or POSTHOG_KEY environment variables",
    );
  }

  const preferredLocale =
    request.headers.get("accept-language")?.split(",")[0] || "en-US";

  const { toast: toastObj, headers: toastHeaders } = await getToast(request);
  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL,
    SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY,
    POSTHOG_KEY: process.env.POSTHOG_KEY,
  };

  const { user, headers: supabaseHeaders } = await getUser(request);
  const userFlags = user
    ? await flagsService.getUserFlags({ userId: user.id })
    : {};
  const adminSession = user
    ? impersonationService.getAdminSession(request)
    : null;

  // Get teacher profile if user exists
  const teacherProfile = user
    ? await profileService.findTeacherProfileByUserId(user.id)
    : null;

  // Merge headers
  const headers = new Headers();
  supabaseHeaders.forEach((value, key) => {
    headers.set(key, value);
  });
  toastHeaders.forEach((value, key) => {
    headers.set(key, value);
  });

  return json(
    {
      env,
      toastObj,
      user,
      preferredLocale,
      isImpersonating: !!adminSession,
      userFlags,
      teacherProfile,
    },
    { headers },
  );
};

export const ErrorBoundary = RootErrorBoundary;

export default function App() {
  const {
    env,
    toastObj,
    user: initialUser,
    preferredLocale,
    isImpersonating,
    userFlags,
    teacherProfile,
  } = useLoaderData<typeof loader>();
  const [user, setUser] = useState<(User & { id: Tuuid }) | null>(initialUser);

  useEffect(() => {
    if (toastObj?.type === "error") {
      if (
        // We handle the error in the QuestionResponse component
        // This is delayed until the next question is loaded which
        // is confusing to the user. (because we are skipping revalidation
        // for the actions).
        toastObj.message ===
        "An error occured while trying to save the response. Please refresh the page."
      ) {
        return;
      }
      showErrorToast(toastObj.message);
    }
    if (toastObj?.type === "success") {
      showSuccessToast(toastObj.message);
    }
  }, [toastObj]);

  const [supabase] = useState(() =>
    createBrowserClient(env.SUPABASE_URL, env.SUPABASE_ANON_KEY),
  );

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        setUser(session.user as User & { id: Tuuid });
      } else {
        setUser(null);
      }
    });
    return () => subscription.unsubscribe();
  }, [supabase]);

  return (
    <html lang="en" {...mantineHtmlProps}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body id="app">
        <MantineProvider theme={customTheme}>
          <DatesProvider settings={{ locale: preferredLocale }}>
            <Notifications />
            {isImpersonating && <ImpersonationBanner />}
            <Outlet context={{ supabase, user, userFlags, teacherProfile }} />
            <ScrollRestoration />
            <Posthog posthogKey={env.POSTHOG_KEY} user={user} />
            <Scripts />
          </DatesProvider>
        </MantineProvider>
      </body>
    </html>
  );
}
