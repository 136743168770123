import { createTheme } from "@mantine/core";
import { colors, toMantineColors } from "./colors";

export const customTheme = createTheme({
  colors: {
    primary: toMantineColors(colors.primary),
    secondary: toMantineColors(colors.secondary),
  },

  primaryColor: "primary",
  primaryShade: 5,

  components: {
    Button: {
      defaultProps: {
        color: "primary",
      },
    },
    ActionIcon: {
      defaultProps: {
        color: "primary",
      },
    },
    Badge: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});
