import { ColorSchemeScript, mantineHtmlProps } from "@mantine/core";
import { Links, Meta, Scripts, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

export function RootErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  // Log errors when running locally
  if (
    typeof window !== "undefined" &&
    window.location.hostname === "localhost"
  ) {
    console.log("Error boundary caught:", error);
  }

  return (
    <html lang="en" {...mantineHtmlProps}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .header {
            height: 60px;
            background-color: #0B5D81;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
          }
          .logo-container {
            height: 100%;
            display: flex;
            align-items: center;
          }
          .logo {
            max-height: 48px;
          }
          .error-container {
            min-height: 100vh;
            background-color: rgba(248, 250, 252, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            padding-top: 60px; /* Account for header height */
          }
          .error-content {
            max-width: 28rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .error-title {
            font-size: 1.25rem;
            font-weight: 500;
            color: #334155;
            margin-bottom: 0.75rem;
          }
          .error-message {
            color: #475569;
            text-align: center;
            margin-bottom: 1.5rem;
          }
          .error-buttons {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-top: 0.5rem;
          }
          .btn {
            width: 100%;
            padding: 0.5rem 1rem;
            border-radius: 0.375rem;
            cursor: pointer;
            transition: background-color 0.2s;
          }
          .btn-primary {
            background-color: #eff6ff;
            color: #2563eb;
          }
          .btn-primary:hover {
            background-color: #dbeafe;
          }
          .btn-secondary {
            background-color: #faf5ff;
            color: #7e22ce;
          }
          .btn-secondary:hover {
            background-color: #f3e8ff;
          }
        `,
          }}
        />
      </head>
      <body>
        <header className="header">
          <div className="logo-container">
            <a href="/">
              <img
                src="/assets/podsie-full_logo-green.png"
                alt="Podsie logo"
                className="logo"
              />
            </a>
          </div>
        </header>
        <div className="error-container">
          <div className="error-content">
            <h2 className="error-title">We encountered an issue 😕</h2>
            <p className="error-message">
              We&apos;re sorry for the inconvenience. Please try refreshing the
              page or return to the home page.
            </p>
            <div className="error-buttons">
              <button
                onClick={() => window.location.reload()}
                className="btn btn-primary"
              >
                Refresh page
              </button>
              <button
                onClick={() => (window.location.href = "/")}
                className="btn btn-secondary"
              >
                Return to home page
              </button>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
