import { Alert, Button, Group } from "@mantine/core";
import { Form } from "@remix-run/react";
import { TbArrowBack } from "react-icons/tb";

export function ImpersonationBanner() {
  return (
    <Alert
      color="yellow"
      className="absolute right-10 top-0 rounded-md z-[9999] backdrop-blur-sm border-yellow-600"
    >
      <Group justify="space-between">
        <div>You are currently impersonating another user.</div>
        <Form action="/admin/end-impersonation" method="post">
          <Button
            size="xs"
            variant="light"
            color="yellow"
            className="bg-yellow-700"
            leftSection={<TbArrowBack size={16} />}
            type="submit"
          >
            End Impersonation
          </Button>
        </Form>
      </Group>
    </Alert>
  );
}
